import React from "react"

import { Layout, Seo, classes } from "../../layout"
import { MdHotel } from "react-icons/md"
import { ImWrench } from "react-icons/im"
import { BiBuildingHouse } from "react-icons/bi"

import { FindOutMoreButton, img } from "../../components"

export default function IndexPage(props) {
  const { heading } = classes
  const section = "p-6 md:p-16 max-w-5xl mx-auto"
  const sub_heading =
    "flex justify-left items-center mb-4 text-base sm:text-xl font-bold"
  const paragraph = "my-6"

  return (
    <Layout language="no" path={props.path}>
      <Seo title="Tilstedeværelse i Trailguide-appen" />

      <div className={section + " mt-12"}>
        <h1 className={heading}>Tilstedeværelse i Trailguide-appen</h1>
        <p className="py-2">
          Trailguide brukes av hundretusenvis hvert år for å finne stier og
          turer de kan oppleve. Appen brukes også for å finne nye steder å reise
          til når de planlegger deres neste ferie.
        </p>
        <p className="py-2">
          Hvis du jobber i en offisiell turistregion, destinasjon, resort eller
          en annen lokal bedrift som tilbyr tjenester gjester og besøkende
          kanskje trenger, så kan du legge til bedriften din på kartet i
          Trailguide.
        </p>
      </div>

      <div id="service" className={section}>
        <div className={sub_heading}>
          <Icon>
            <ImWrench className="w-full h-full" />
          </Icon>
          <div>Synlig som en lokal tjeneste</div>
        </div>
        <p className={paragraph}>
          Har du et lite hotell, en campingplass, restaurant, café, gjestgiveri,
          shuttle-tjeneste, guide-tjeneste, sportsbutikk eller sykkelverksted så
          kan du bli synlig i Trailguide-appen.
        </p>
        <p className={paragraph}>
          Du vil være synlig fra <b>zoom-nivå 12</b> på kartet med et symbol som
          representer den tjeneste du tilbyr. Du vil også være synlig på hver
          eneste aktivitetsside <b>innenfor 20km</b> fra din lokasjon.
        </p>
        <p className={paragraph}>
          Om brukeren klikker på symbolet vil det vises informasjon om deg og
          hvilke tjenester du tilbyr samt kontaktinformasjon som epost, telefon,
          webside, facebook og hvilken rute man må ta for å komme til deg.
        </p>
        <p className={paragraph}>
          Prisen er <b>kr 4.000 per år</b>.
        </p>

        <MoreButton
          href="https://trailguide.net/order?lang=no"
          target="trailguide.net"
        >
          Kjøp synlighet nå
        </MoreButton>

        <div className="sm:flex justify-center mt-12">
          <div className="sm:w-1/2">
            <img
              alt="Local service on the map"
              src={img("/website/local-service-phone-map-android.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 text-sm mx-auto max-w-sm italic">
              Sykkelverksteded Tretterud Sport vises på kartet som en
              skiftnøkkel, symbolet for et verksted. Detaljert informasjonen om
              Tretterud Sport vises når brukerne klikker på ikonet.
            </div>
          </div>
          <div className="sm:w-1/2">
            <img
              alt="Local service in activity page"
              src={img("/website/local-service-phone-page-android.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 text-sm mx-auto max-w-sm italic">
              Du vil også være synlig på alle aktivitetssider innenfor en radius
              av 20km fra din lokasjon.
            </div>
          </div>
        </div>
      </div>

      <div id="resort" className={section}>
        <div className={sub_heading}>
          <Icon>
            <MdHotel className="w-full h-full" />
          </Icon>
          <div>Synlighet som en resort</div>
        </div>
        <p className={paragraph}>
          Driver du et større hotell eller turistresort kan du legge det til på
          kartet i Trailguide-appen så brukere vil finne deg. Du vil bli synlig
          med et ikon på kartet fra <b>zoom-nivå 11</b> og ikonet vil ha din
          logo.
        </p>
        <p className={paragraph}>
          Per default vises informasjon om din resort om brukeren klikker på
          symbolet. Det vises informasjon om hva du tilbyr samt detaljer slik
          som telefonnummer, webside, facebook samt en egen link til
          kjørehenvisninger i google maps.
        </p>
        <p className={paragraph}>
          Du kan også kombinere synlighet som en resort med din egen
          landingsside på Trailguide. Da vil denne åpnes om brukeren klikker på
          ikonet å kartet.
        </p>
        <p className={paragraph}>
          Prisen er <b>kr 8.000 per år</b>.
        </p>

        <div className="sm:flex justify-center mt-12">
          <div className="sm:w-1/3">
            <img
              alt="Resort on the map"
              src={img("/website/trailguide-phone-resort.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              Det flotte fjellhotellet Storefjell Resort er synlig på kartet med
              deres egen logo.
            </div>
          </div>
          <div className="sm:w-1/3">
            <img
              alt="Landing page - top"
              src={img("/website/trailguide-phone-landingpage-storefjell.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              Hotellets landingsside åpnes om man klikker på ikonet på kartet.
              Siden viser hvilke tjenester som tilbys og en beskrivelse av
              hotellet sammen med et bildet av fjellområdet.
            </div>
          </div>
          <div className="sm:w-1/3">
            <img
              alt="Landing page - content"
              src={img(
                "/website/trailguide-phone-landingpage-storefjell2.jpeg"
              )}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              Landingssiden lister også opp turer og aktiviteter tilgjengelig
              utenfor hotelldøren.
            </div>
          </div>
        </div>
      </div>

      <div id="destination" className={section}>
        <div className={sub_heading}>
          <Icon>
            <BiBuildingHouse className="w-full h-full" />
          </Icon>
          <div>Synlighet som en destinasjon</div>
        </div>
        <p className={paragraph}>
          Du kan legge til destinasjonen din på Trailguide-kartet og la folk
          finne både deg, lokale tjenester og lokale aktiviteter og turer.
          Destinasjonen vil være synlig som et ikon på kartet fra{" "}
          <b>zoom-nivå 9</b> med din egen logo.
        </p>
        <p className={paragraph}>
          Ved å kombinere dette med din egen <b>landingsside</b> vi denne åpnes
          når brukeren klikker på ikonet på kartet.
        </p>
        <p className={paragraph}>
          Prisen er <b>kr 15.000 per år</b> for synlighet som en destinasjon.
        </p>

        <div className="sm:flex justify-center mt-12">
          <div className="sm:w-1/3">
            <img
              alt="Resort on the map"
              src={img("/website/trailguide-destination-gol.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              Gol i Hallingdal er en egen turistdestinasjon og vises på kartet
              der hvor turistkontoret ligger, midt i sentrum.
            </div>
          </div>
          <div className="sm:w-1/3">
            <img
              alt="Landing page - top"
              src={img("/website/trailguide-destination-gol-landingpage.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              Landingssiden viser offisielle turer og utendørsaktiviteter
              tilgjengelig for destinasjonen. Brukeren kan enkelt finne frem ved
              å bruke et filter.
            </div>
          </div>
          <div className="sm:w-1/3">
            <img
              alt="Landing page - content"
              src={img("/website/trailguide-destination-gol-services2.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              Du kan også vise tilgjengelige lokale tjenester i landingssiden
              din.
            </div>
          </div>
        </div>
      </div>

      <div id="region" className={section}>
        <div className={sub_heading}>
          <Icon>
            <BiBuildingHouse className="w-full h-full" />
          </Icon>
          <div>Synlighet som en turistregion</div>
        </div>
        <p className={paragraph}>
          Du kan legge til en hel region på Trailguide-kartet og la folk finne
          både regionen, destinasjonene i regionen og offisielle regionale
          aktiviteter og turer. Regionen vil være synlig som et ikon på kartet
          fra <b>zoom-nivå 7</b> med din egen logo.
        </p>
        <p className={paragraph}>
          Prisen er <b>kr 30.000 per år</b>.
        </p>

        <div className="sm:flex justify-center mt-12">
          <div className="sm:w-1/3">
            <img
              alt="Resort on the map"
              src={img("/website/trailguide-region-rides2.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              Det regionale terrengsykkelprosjektet Hallingdal Rides er
              representert på kartet med et eget ikon i Hallingdal.
            </div>
          </div>
          <div className="sm:w-1/3">
            <img
              alt="Landing page - top"
              src={img("/website/trailguide-region-rides-landingpage.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              Ved å klikke på ikonet på kartet åpnes regionens landingsside
              (krever lisens til landingsside-modulen).
            </div>
          </div>
          <div className="sm:w-1/3">
            <img
              alt="Landing page - content"
              src={img("/website/trailguide-region-rides-landingpage2.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              Landingssiden viser alle offisielle, opparbeidede og tilrettelagte
              sykkelstier i Hallingdalsregionen.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

function Icon(props) {
  return (
    <div className="w-8 h-8 p-1 sm:w-10 sm:h-10 sm:p-2 mr-4 bg-blue-500 text-blue-100 rounded-full">
      {props.children}
    </div>
  )
}

function MoreButton(props) {
  return <FindOutMoreButton className="my-4" {...props} />
}
